import React from 'react';

import {Section} from 'src/components/molecules/Section';
// import { LogoFlow } from 'src/components/logo-flow';
import {Link} from 'src/containers/Link';
import {Button} from 'src/components/atoms/Button';
import {InlineList} from 'src/components/molecules/InlineList';
import * as css from './drawer-logos.css';

export default function LegacySection({heading, content, contentNode, callouts, ctas, customData}) {
    customData = JSON.parse(customData);
    const customerList = callouts.find(({__typename}) => __typename == 'DatoCmsCustomerListBlock').customers;
    return (
        <Section {...customData?.flags}>
            {!!heading &&
                <h2 className={customData?.flags?.monoHeader && 'f-mono'}
                    style={customData?.flags?.monoHeader && {fontSize: '15px'}}>
                    {heading}
                </h2>
            }
            {content.length > 0 && (
                <div
                    dangerouslySetInnerHTML={{
                        __html: contentNode.childMarkdownRemark.html,
                    }}
                />
            )}
            {/* TODO: Revisit the CTA's logic below */}
            {ctas && ctas.length > 0 && (
                <>
                    {ctas[1] && !!ctas[1] && <InlineList centered>
                        <li>
                            <Button to={ctas[1].url}>{ctas[1].text}</Button>
                        </li>
                    </InlineList>}
                    <Link to={ctas[0].url} css={css.flowLinkBlock}>
                        <LogoFlow {...customData?.flags}>
                            {customerList.map(({name, logo}) => {
                                const {width, height, aspect = width / height} = logo;
                                const dimensions =
                                    aspect < 1.5
                                        ? {height: 65, width: 65 * aspect}
                                        : aspect < 4
                                            ? {height: 40, width: 40 * aspect}
                                            : {width: 200, height: 200 / aspect};
                                return <img src={logo.url} alt={logo.alt} {...dimensions} key={name}/>;
                            })}
                        </LogoFlow>
                    </Link>
                </>
            )}
        </Section>
    );
}

function LogoFlow({children, grayscale = true, opacity = 0.5}) {
    return (
        <div style={{padding: 0.05, filter: grayscale && 'grayscale(1)' || 'none', opacity}}>
            <div css={css.flowOuter}>
                {React.Children.map(children, (child) => (
                    <div css={css.flowInner}>{child}</div>
                ))}
            </div>
        </div>
    );
}
