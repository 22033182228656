import { css } from 'styled-components';

export const flowLinkBlock = css`
  display: block;
  margin-top: 50px;
`;

export const flowOuter = css`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin: -1em -1.5em;
  justify-content: center;
`;

export const flowInner = css`
  flex: 0 0 auto;
  width: max-content;
  padding: 1em 1.5em;
`;
